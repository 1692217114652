import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import Preloader from "../components/Preloader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [displayedTransactions, setDisplayedTransactions] = useState(10);
  const [recordsToDisplay, setRecordsToDisplay] = useState(
    displayedTransactions
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchingForTransaction, setSearchingForTransaction] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const navigate = useNavigate();

  const loadMoreRecords = () => {
    setRecordsToDisplay(recordsToDisplay + displayedTransactions);
  };

  const toggleSearchActive = () => {
    setSearchActive(!searchActive);
  };

  useEffect(() => {
    const token = localStorage.getItem("cathay-admin");

    const getTransactions = async () => {
      const { data } = await axios.get(
        "https://app.cathaynational.com/api/transaction",
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setTransactions(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getTransactions();
  }, []);

  useEffect(() => {
    if (searchingForTransaction) {
      const foundTransaction = transactions?.filter((transaction: any) => {
        return Object.values(transaction.user.firstName)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchResults(foundTransaction);

      if (foundTransaction.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    }
  }, [searchTerm, transactions, searchingForTransaction]);

  const approve = async () => {
    const token = localStorage.getItem("cathay-admin");

    setLoading(true);

    try {
      await axios.put(
        "https://app.cathaynational.com/api/transaction/approve",
        { transactionId },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Transaction approved!");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const reject = async () => {
    const token = localStorage.getItem("cathay-admin");

    setLoading(true);

    try {
      await axios.put(
        "https://app.cathaynational.com/api/transaction/reject",
        { transactionId },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Transaction rejected!");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const deleteTransaction = async () => {
    const token = localStorage.getItem("cathay-admin");

    setLoading(true);

    try {
      await axios.delete(
        `https://app.cathaynational.com/api/transaction/${transactionId}`,

        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Transaction deleted!");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      {pageLoading ? (
        <Preloader />
      ) : (
        <div className="nk-body bg-lighter npc-general has-sidebar ui-bordered ">
          <div className="nk-app-root">
            <div className="nk-main ">
              <Sidebar />
              <div className="nk-wrap ">
                <Header />
                <div className="nk-content ">
                  <div className="container-fluid">
                    <div className="nk-content-inner">
                      <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                          <div className="nk-block-between">
                            <div className="nk-block-head-content">
                              <h3 className="nk-block-title page-title">
                                Transactions
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="nk-block">
                          <div className="card card-bordered card-stretch">
                            <div className="card-inner-group">
                              <div className="card-inner">
                                <div className="card-title-group">
                                  <div className="card-title" />
                                  <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                      <li>
                                        <a
                                          href="#/"
                                          onClick={toggleSearchActive}
                                          className="search-toggle toggle-search btn btn-icon"
                                          data-target="search"
                                        >
                                          <em className="icon ni ni-search" />
                                        </a>
                                      </li>
                                      <li className="btn-toolbar-sep" />
                                      {/* <li>
                                  <div className="dropdown">
                                    <a
                                      href="#/"
                                      className="btn btn-trigger btn-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                    >
                                      <em className="icon ni ni-filter-alt" />
                                    </a>
                                    <div className="filter-wg dropdown-menu dropdown-menu-xl dropdown-menu-end">
                                      <div className="dropdown-head">
                                        <span className="sub-title dropdown-title">
                                          Filter
                                        </span>
                                        <div className="dropdown">
                                          <a
                                            href="#/"
                                            className="link link-light"
                                          >
                                            <em className="icon ni ni-more-h" />
                                          </a>
                                        </div>
                                      </div>
                                      <div className="dropdown-body dropdown-body-rg">
                                        <div className="row gx-6 gy-4">
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="overline-title overline-title-alt">
                                                Type
                                              </label>
                                              <select className="form-select js-select2">
                                                <option value="any">
                                                  Any Type
                                                </option>
                                                <option value="deposit">
                                                  Deposit
                                                </option>
                                                <option value="buy">
                                                  Buy Coin
                                                </option>
                                                <option value="sell">
                                                  Sell Coin
                                                </option>
                                                <option value="transfer">
                                                  Transfer
                                                </option>
                                                <option value="withdraw">
                                                  Withdraw
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="overline-title overline-title-alt">
                                                Status
                                              </label>
                                              <select className="form-select js-select2">
                                                <option value="any">
                                                  Any Status
                                                </option>
                                                <option value="pending">
                                                  Pending
                                                </option>
                                                <option value="cancel">
                                                  Cancel
                                                </option>
                                                <option value="process">
                                                  Process
                                                </option>
                                                <option value="completed">
                                                  Completed
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="overline-title overline-title-alt">
                                                Pay Currency
                                              </label>
                                              <select className="form-select js-select2">
                                                <option value="any">
                                                  Any Coin
                                                </option>
                                                <option value="bitcoin">
                                                  Bitcoin
                                                </option>
                                                <option value="ethereum">
                                                  Ethereum
                                                </option>
                                                <option value="litecoin">
                                                  Litecoin
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="overline-title overline-title-alt">
                                                Method
                                              </label>
                                              <select className="form-select js-select2">
                                                <option value="any">
                                                  Any Method
                                                </option>
                                                <option value="paypal">
                                                  PayPal
                                                </option>
                                                <option value="bank">
                                                  Bank
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group">
                                              <div className="custom-control custom-control-sm custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input"
                                                  id="includeDel"
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="includeDel"
                                                >
                                                  {" "}
                                                  Including Deleted
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-12">
                                            <div className="form-group">
                                              <button
                                                type="button"
                                                className="btn btn-secondary"
                                              >
                                                Filter
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="dropdown-foot between">
                                        <a className="clickable" href="#/">
                                          Reset Filter
                                        </a>
                                        <a
                                          href="#savedFilter"
                                          data-bs-toggle="modal"
                                        >
                                          Save Filter
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </li> */}
                                      <li>
                                        <div className="dropdown">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                          >
                                            <em className="icon ni ni-setting" />
                                          </a>
                                          <div className="dropdown-menu dropdown-menu-xs dropdown-menu-end">
                                            <ul className="link-check">
                                              <li>
                                                <span>Show</span>
                                              </li>
                                              <li
                                                onClick={() =>
                                                  setDisplayedTransactions(10)
                                                }
                                                className={
                                                  displayedTransactions === 10
                                                    ? "active"
                                                    : ""
                                                }
                                              >
                                                <a href="#/">10</a>
                                              </li>
                                              <li
                                                onClick={() =>
                                                  setDisplayedTransactions(20)
                                                }
                                                className={
                                                  displayedTransactions === 20
                                                    ? "active"
                                                    : ""
                                                }
                                              >
                                                <a href="#/">20</a>
                                              </li>
                                              <li
                                                onClick={() =>
                                                  setDisplayedTransactions(50)
                                                }
                                                className={
                                                  displayedTransactions === 50
                                                    ? "active"
                                                    : ""
                                                }
                                              >
                                                <a href="#/">50</a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    className={`card-search search-wrap ${
                                      searchActive ? "active" : ""
                                    }`}
                                    data-search="search"
                                  >
                                    <div className="search-content">
                                      <a
                                        href="#/"
                                        onClick={toggleSearchActive}
                                        className="search-back btn btn-icon toggle-search"
                                        data-target="search"
                                      >
                                        <em className="icon ni ni-arrow-left" />
                                      </a>
                                      <input
                                        type="text"
                                        className="form-control border-transparent form-focus-none"
                                        placeholder="Search by user first name"
                                        onChange={(e) => {
                                          setSearchingForTransaction(true);
                                          setSearchTerm(e.target.value);
                                        }}
                                      />
                                      <button className="search-submit btn btn-icon">
                                        <em className="icon ni ni-search" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="nk-tb-list is-separate is-medium mb-3">
                                <div className="nk-tb-item nk-tb-head">
                                  <div className="nk-tb-col">
                                    <span>ID</span>
                                  </div>
                                  <div className="nk-tb-col tb-col-md">
                                    <span>Date</span>
                                  </div>
                                  <div className="nk-tb-col">
                                    <span className="d-none d-sm-block">
                                      Status
                                    </span>
                                  </div>
                                  <div className="nk-tb-col tb-col-sm">
                                    <span>User</span>
                                  </div>
                                  <div className="nk-tb-col tb-col-md">
                                    <span>Type</span>
                                  </div>
                                  <div className="nk-tb-col">
                                    <span>Amount</span>
                                  </div>
                                  <div className="nk-tb-col nk-tb-col-tools"></div>
                                </div>
                                {!searchTerm && transactions?.length > 0 && (
                                  <>
                                    {transactions
                                      ?.slice(0, recordsToDisplay)
                                      ?.map((transaction: any) => (
                                        <div
                                          className="nk-tb-item"
                                          key={transaction._id}
                                        >
                                          <div className="nk-tb-col">
                                            <span className="tb-lead">
                                              <a href="#/">
                                                #{transaction.transactionId}
                                              </a>
                                            </span>
                                          </div>
                                          <div className="nk-tb-col tb-col-md">
                                            <span className="tb-sub">
                                              {new Date(
                                                transaction?.dateTime
                                              )?.toDateString()}
                                            </span>
                                          </div>
                                          {transaction.status === "Pending" && (
                                            <div className="nk-tb-col">
                                              <span className="dot bg-warning d-sm-none" />
                                              <span className="badge badge-sm badge-dot has-bg bg-warning d-none d-sm-inline-flex">
                                                Pending
                                              </span>
                                            </div>
                                          )}
                                          {transaction.status ===
                                            "Unconfirmed" && (
                                            <div className="nk-tb-col">
                                              <span className="dot bg-warning d-sm-none" />
                                              <span className="badge badge-sm badge-dot has-bg bg-warning d-none d-sm-inline-flex">
                                                Unconfirmed
                                              </span>
                                            </div>
                                          )}
                                          {transaction.status ===
                                            "Completed" && (
                                            <div className="nk-tb-col">
                                              <span className="dot bg-success d-sm-none" />
                                              <span className="badge badge-sm badge-dot has-bg bg-success d-none d-sm-inline-flex">
                                                Completed
                                              </span>
                                            </div>
                                          )}
                                          {transaction.status ===
                                            "Cancelled" && (
                                            <div className="nk-tb-col">
                                              <span className="dot bg-danger d-sm-none" />
                                              <span className="badge badge-sm badge-dot has-bg bg-danger d-none d-sm-inline-flex">
                                                Cancelled
                                              </span>
                                            </div>
                                          )}
                                          <div className="nk-tb-col tb-col-sm">
                                            <span className="tb-sub">
                                              {transaction.user.firstName}{" "}
                                              {transaction.user.lastName}
                                            </span>
                                          </div>
                                          <div className="nk-tb-col tb-col-md">
                                            {transaction.type}
                                          </div>
                                          <div className="nk-tb-col">
                                            <span className="tb-lead">
                                              $
                                              {transaction.amount?.toLocaleString()}
                                            </span>
                                          </div>
                                          <div className="nk-tb-col nk-tb-col-tools">
                                            <ul className="nk-tb-actions gx-1">
                                              <li>
                                                <div className="drodown me-n1">
                                                  <a
                                                    href="#/"
                                                    className="dropdown-toggle btn btn-icon btn-trigger"
                                                    data-bs-toggle="dropdown"
                                                  >
                                                    <em className="icon ni ni-more-h" />
                                                  </a>
                                                  <div className="dropdown-menu dropdown-menu-end">
                                                    <ul className="link-list-opt no-bdr">
                                                      <li>
                                                        <Link
                                                          to={`/transaction/${transaction.transactionId}`}
                                                        >
                                                          <em className="icon ni ni-eye" />
                                                          <span>
                                                            Transaction Details
                                                          </span>
                                                        </Link>
                                                      </li>
                                                      {(transaction.status ===
                                                        "Pending" ||
                                                        transaction.status ===
                                                          "Unconfirmed") && (
                                                        <li>
                                                          <a
                                                            href="#/"
                                                            onClick={() => {
                                                              setTransactionId(
                                                                transaction.transactionId
                                                              );
                                                              setApproveModalOpen(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            <em className="icon ni ni-money" />
                                                            <span>Approve</span>
                                                          </a>
                                                        </li>
                                                      )}
                                                      {(transaction.status ===
                                                        "Pending" ||
                                                        transaction.status ===
                                                          "Unconfirmed") && (
                                                        <li>
                                                          <a
                                                            href="#/"
                                                            onClick={() => {
                                                              setTransactionId(
                                                                transaction.transactionId
                                                              );
                                                              setRejectModalOpen(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            <em className="icon ni ni-trash" />
                                                            <span>Reject</span>
                                                          </a>
                                                        </li>
                                                      )}
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setTransactionId(
                                                              transaction.transactionId
                                                            );
                                                            setDeleteModalOpen(
                                                              true
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-trash" />
                                                          <span>Delete</span>
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ))}
                                  </>
                                )}
                                {searchTerm && searchResults?.length > 0 && (
                                  <>
                                    {searchResults?.map((transaction: any) => (
                                      <div
                                        className="nk-tb-item"
                                        key={transaction._id}
                                      >
                                        <div className="nk-tb-col">
                                          <span className="tb-lead">
                                            <a href="#/">
                                              #{transaction.transactionId}
                                            </a>
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <span className="tb-sub">
                                            {new Date(
                                              transaction?.dateTime
                                            )?.toDateString()}
                                          </span>
                                        </div>
                                        {transaction.status === "Pending" && (
                                          <div className="nk-tb-col">
                                            <span className="dot bg-warning d-sm-none" />
                                            <span className="badge badge-sm badge-dot has-bg bg-warning d-none d-sm-inline-flex">
                                              Pending
                                            </span>
                                          </div>
                                        )}
                                        {transaction.status ===
                                          "Unconfirmed" && (
                                          <div className="nk-tb-col">
                                            <span className="dot bg-warning d-sm-none" />
                                            <span className="badge badge-sm badge-dot has-bg bg-warning d-none d-sm-inline-flex">
                                              Unconfirmed
                                            </span>
                                          </div>
                                        )}
                                        {transaction.status === "Completed" && (
                                          <div className="nk-tb-col">
                                            <span className="dot bg-success d-sm-none" />
                                            <span className="badge badge-sm badge-dot has-bg bg-success d-none d-sm-inline-flex">
                                              Completed
                                            </span>
                                          </div>
                                        )}
                                        {transaction.status === "Cancelled" && (
                                          <div className="nk-tb-col">
                                            <span className="dot bg-danger d-sm-none" />
                                            <span className="badge badge-sm badge-dot has-bg bg-danger d-none d-sm-inline-flex">
                                              Cancelled
                                            </span>
                                          </div>
                                        )}
                                        <div className="nk-tb-col tb-col-sm">
                                          <span className="tb-sub">
                                            {transaction.user.firstName}{" "}
                                            {transaction.user.lastName}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          {transaction.type}
                                        </div>
                                        <div className="nk-tb-col">
                                          <span className="tb-lead">
                                            $
                                            {transaction.amount?.toLocaleString()}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col nk-tb-col-tools">
                                          <ul className="nk-tb-actions gx-1">
                                            <li>
                                              <div className="drodown me-n1">
                                                <a
                                                  href="#/"
                                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  <em className="icon ni ni-more-h" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                  <ul className="link-list-opt no-bdr">
                                                    <li>
                                                      <Link
                                                        to={`/transaction/${transaction.transactionId}`}
                                                      >
                                                        <em className="icon ni ni-eye" />
                                                        <span>
                                                          Transaction Details
                                                        </span>
                                                      </Link>
                                                    </li>
                                                    {(transaction.status ===
                                                      "Pending" ||
                                                      transaction.status ===
                                                        "Unconfirmed") && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setTransactionId(
                                                              transaction.transactionId
                                                            );
                                                            setApproveModalOpen(
                                                              true
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-money" />
                                                          <span>Approve</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    {(transaction.status ===
                                                      "Pending" ||
                                                      transaction.status ===
                                                        "Unconfirmed") && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setTransactionId(
                                                              transaction.transactionId
                                                            );
                                                            setRejectModalOpen(
                                                              true
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-trash" />
                                                          <span>Reject</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    <li>
                                                      <a
                                                        href="#/"
                                                        onClick={() => {
                                                          setTransactionId(
                                                            transaction.transactionId
                                                          );
                                                          setDeleteModalOpen(
                                                            true
                                                          );
                                                        }}
                                                      >
                                                        <em className="icon ni ni-trash" />
                                                        <span>Delete</span>
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ))}
                                  </>
                                )}
                                {(noResults || transactions?.length === 0) && (
                                  <p
                                    style={{
                                      position: "relative",
                                      left: "50%",
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    No records
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {recordsToDisplay < transactions?.length && (
                    <div className="text-center pt-4">
                      <a
                        href="#/"
                        className="link link-soft"
                        onClick={loadMoreRecords}
                      >
                        <em className="icon ni ni-redo" />
                        <span>Load More</span>
                      </a>
                    </div>
                  )}
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        position="bottom-center"
        theme="dark"
        autoClose={3000}
        closeButton={false}
      />

      {approveModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setApproveModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Approve Transaction</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to approve this transaction?
                      </p>
                    </div>
                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Approving...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={approve}
                        >
                          Approve
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setApproveModalOpen(false)}
          />
        </>
      )}

      {rejectModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setRejectModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Reject Transaction</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to reject this transaction?
                      </p>
                    </div>

                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Proceeding...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={reject}
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setRejectModalOpen(false)}
          />
        </>
      )}

      {deleteModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setDeleteModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Delete Transaction</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to delete this transaction?
                      </p>
                    </div>

                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Proceeding...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={deleteTransaction}
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setDeleteModalOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default Transactions;
